import { useRecoilState } from 'recoil';
import { findInDictionary } from 'helpers/parse';
import { inputState } from 'state';
import DaumPostcodeEmbed from 'react-daum-postcode';
import { Box, Button, Typography } from '@newulife/common';
import { useEffect, useState, useMemo } from 'react';
import Input from 'components/common/inputs/Input';
import { find } from 'lodash'

/**
 * @params
 * fields = array of strings defined in dictionary
 * isValid = function that when called with true validates the section
 * type = (optional: default is 'account') if present all ids will be prepended with string.  Service Objects uses to map and it will be the property on AccountContext.so where address is saved
 */

function KRAddressSection(props) {
  // props
  const {
    label,
    formType,
    dictionary,
    isValid: isValidCallback,
    setAddress: setServiceObjectsAddress,
  } = props;

  const [
    changeAddressLabel,
  ] = useMemo(() => {
    return [
      find(dictionary, { id: 'KOREA_CHANGE_ADDRESS_BUTTON' })?.text,
    ]
  }, [dictionary])

  // local constants
  const countryText = findInDictionary(dictionary, 'COUNTRY');
  const getStateRef = (field) =>
    `${formType.toUpperCase()}_${field.toUpperCase()}`;
  const address1 = getStateRef('ADDRESS1');
  const address2 = getStateRef('ADDRESS2');
  const city = getStateRef('CITY');
  const state = getStateRef('STATE');
  const zip = getStateRef('ZIP');

  const [inputsState, set_inputsState] = useRecoilState(inputState);
  const [isAddressSaved, set_isAddressSaved] = useState(
    !!inputsState[address1] &&
      !!inputsState[city] &&
      !!inputsState[state] &&
      !!inputsState[zip],
  );

  useEffect(() => {
    if (isAddressSaved) {
      isValidCallback(true);
    } else {
      isValidCallback(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDaumComplete = (data) => {
    const address = {
      [address1]: data.address,
      [city]: data.sido,
      [state]: data.sigungu,
      [zip]: data.zonecode,
    };

    set_inputsState((prev) => {
      return {
        ...prev,
        ...address,
      };
    });

    setServiceObjectsAddress(formType, address);
    set_isAddressSaved(true);
    isValidCallback(true);
  };

  const onChangeAddressClicked = () => {
    const address = {
      [address1]: '',
      [address2]: '',
      [city]: '',
      [state]: '',
      [zip]: '',
    };

    set_inputsState((prev) => {
      return {
        ...prev,
        ...address,
      };
    });

    setServiceObjectsAddress(formType, address);
    set_isAddressSaved(false);
    isValidCallback(false);
  };

  function onAddress2Change(id, value) {
    set_inputsState((prev) => {
      return {
        ...prev,
        [id]: value,
      };
    });

    const address = {
      [address1]: inputsState[address1],
      [address2]: value,
      [city]: inputsState[city],
      [state]: inputsState[state],
      [zip]: inputsState[zip],
    }

    setServiceObjectsAddress(formType, address);
  }

  return (
    <fieldset
      data-testid={`${formType.toUpperCase()}_ADDRESS_FIELDSET`}
      className="form-max flex-20 column"
    >
      <p className="address-country">
        {countryText}: <strong>{label}</strong>
      </p>
      {isAddressSaved ? (
        <Box
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <Typography>{inputsState[address1]}</Typography>
          <Typography sx={{ mb: 1 }}>{inputsState[zip]}</Typography>
          <Input
            key={`${formType}-section-input-1`}
            id="ADDRESS2"
            field="ADDRESS2"
            stateRef={address2}
            label="ADDRESS2"
            country="KR"
            formType={formType}
            dictionary={dictionary}
            onChange={onAddress2Change}
            value={inputsState[address2]}
            required={false}
          />
          <Button
            variant="outlined"
            onClick={onChangeAddressClicked}
            sx={{ my: 3 }}
          >
            {changeAddressLabel}
          </Button>
        </Box>
      ) : (
        <DaumPostcodeEmbed
          autoClose={false}
          focusInput={false}
          onComplete={onDaumComplete}
          {...props}
        />
      )}
    </fieldset>
  );
}

export default KRAddressSection;
