import { useState, useEffect, useCallback } from 'react';
import { useModal } from 'contexts/ModalContext';
import { findInDictionary } from 'helpers/parse';
import envConfig from 'config';

import Input, { BlueSwitch } from 'components/common/inputs/Input';
import ServiceObjectsAddressSection from 'components/common/form/components/ServiceObjectsAddressSection';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  formState,
  accountAddress as account_address_state,
  shippingAddress as shipping_address_state,
  inputState,
} from 'state';
import api from 'api';
import { useNextContextConsumer } from 'contexts/NextContext';
import { useLoader } from 'contexts/LoaderContext';
import address from 'api/address';
import useAccountForm from 'hooks/useAccountForm';
import useValidationConsolidator from 'hooks/useValidationConsolidator';
import ShadowCard from 'components/common/cards/ShadowCard';
import { toast } from 'react-toastify';
import KRAddressSection from './KRAddressSection';

/**
 *
 * @param {string} country
 * @param {string} type - string used to declare form
 * @param {function} isValid - function that sets whether or not the section is 'valid'
 * @param {string} title - string above the section
 * @param {string} formStatus - form status passed down to trigger events 'invalid', 'complete', 'validating'
 * @param {function} callback - called after both modals run
 * @param {boolean} duplicate <boolean> that determines if form is shipping + account with toggle hiding account form
 *
 */
/**
 * @TODO modal component functionality should be decoupled from parent state
 * @TODO refactor sections to be more reusable smaller components (i.e. defaultSection, shippingSection, accountSection)
 * component uses formStatus passed from parent to show modals and use modals to validate and pass the validated address back to parent to use in api calls
 */

export default function ShippingSection(props) {
  const {
    config,
    formStatus: { status, setStatus },
    data,
    loaderData,
    inputs: dictionary,
    isValid,
  } = props;
  const [form, setForm] = useRecoilState(formState);
  const inputs = useRecoilValue(inputState);
  const [account_address, set_account_address] = useRecoilState(
    account_address_state,
  );
  const [shipping_address, set_shipping_address] = useRecoilState(
    shipping_address_state,
  );

  const { set: setModal } = useModal();
  const { showLoader, hideLoader } = useLoader();
  const history = useHistory();
  const { setErrorMessage } = useNextContextConsumer();

  const enrollData = useAccountForm();
  const defaultForm = inputs;

  const {
    businessMarket: { iso2: businessIso } = {},
    countryInfo: { iso2: marketIso } = {},
  } = form;

  useEffect(() => {
    if (status === 'validating') {
      handleNextButton();
    }
  }, [status]);

  function setServiceObjects(type, soAddress) {
    const address = type === 'account' ? account_address : shipping_address;
    const call =
      type === 'account' ? set_account_address : set_shipping_address;
    call({
      ...address,
      selected: soAddress ? 'serviceobjects' : 'current',
      serviceobjects: soAddress,
    });
  }
  // catch all error and log then set a client Error message for failure to create user
  const handleNextButton = useCallback(() => {
    const addressArray = [
      {
        ...account_address,
        current: {
          ...Object.keys(inputs)
            .filter((input) => input.includes('ACCOUNT'))
            .reduce(
              (acc, inputKey) => {
                const key = inputKey.split('_')[1]
                  ? inputKey.split('_')[1]
                  : inputKey;
                return { ...acc, [key]: inputs[inputKey] };
              },
              { COUNTRY: businessIso },
            ),
        },
      },
      {
        ...shipping_address,
        current: {
          ...Object.keys(inputs)
            .filter((input) => input.includes('SHIPPING'))
            .reduce(
              (acc, inputKey) => {
                const key = inputKey.split('_')[1]
                  ? inputKey.split('_')[1]
                  : inputKey;
                return { ...acc, [key]: inputs[inputKey] };
              },
              { COUNTRY: marketIso },
            ),
        },
      },
    ];
    if (Object.keys(addressArray[1].current).length === 1)
      addressArray[1].current = undefined;
    getServiceObjectsAddresses(addressArray)
      .then(getModalResponses)
      .then((addresses) => {
        if (!addresses) {
          setStatus('complete');
        } else if (businessIso === 'KR') {
          setForm(prev => {
            return {
              ...prev,
              addresses
            }
          })
          history.push('/steps/commissions-setup')
        } else {
          if (envConfig.FEATURE_FLAG_LOADING_INDICATOR) {
            showLoader({ type: 'animation', loaderData })
          } else {
            showLoader();
          }

          return api.order
            .createAll({ ...enrollData, addresses })
            .then(({ orderId, userId }) => {
              const [top, bottom] = addresses;
              set_account_address(top);
              set_shipping_address(bottom);
              setForm({ ...form, orderId, userId });
              history.push('/steps/review');
            })
            .catch(handleError)
            .finally(hideLoader);
        }
      });
  }, [account_address, shipping_address, inputs, enrollData]);

  function handleError(error) {
    hideLoader();
    // const { code } = error;
    // if (error) setErrorMessage('Server Error - Failed to create User', code);
    // if (error) toast.error('Failed to create user', { toastId: 'createUser', autoClose: false }); We might be able to delete this - given errors from the server are displayed in request.js
    setStatus('complete');
    console.warn(error);
  }
  // takes address array and adds service objects address
  function getServiceObjectsAddresses(addressArray) {
    return Promise.all(
      addressArray.map((address) => {
        if (
          address.selected === 'serviceobjects' &&
          address.current.COUNTRY === 'US'
        )
          return Promise.resolve(address.serviceobjects);
        return api.address.secondaryAddressValidation(address.current);
      }),
    )
      .then((soArray) => {
        return addressArray.map((address, i) => {
          return {
            ...address,
            serviceobjects: soArray[i] || address.serviceobjects,
          };
        });
      })
      .catch(handleError);
  }
  // returns new addresses adds selected prop
  async function getModalResponses(addresses) {
    try {
      const response1 = await openModal(addresses[0]);
      if (response1 === 'cancel') return;
      const response2 = await openModal(addresses[1]);
      if (response2 === 'cancel') return;
      const responses = [response1, response2];

      return addresses.map((thisAddress, i) => {
        return { ...thisAddress, selected: responses[i] };
      });
    } catch (e) {
      handleError(e);
    }
  }
  // returns text response
  function openModal(addressObj) {
    const skip =
      addressObj.selected === 'serviceobjects' && addressObj.current.COUNTRY;
    if (!addressObj.current) return Promise.resolve();
    if (skip) return Promise.resolve('serviceobjects');
    const type = addressObj.serviceobjects
      ? 'confirm-address'
      : 'invalid-address';
    return new Promise((resolve) => {
      const callback = ({ status: modalStatus }) => {
        resolve(modalStatus);
      };
      setModal({
        data: {
          addresses: addressObj,
        },
        type,
        addressType: address.addressType,
        callback,
        open: true,
      });
    });
  }

  const newProps = {
    data,
    config,
    dictionary,
    isValid,
    defaultForm,
    setAddress: setServiceObjects,
  };

  const { title } = data || {};
  return (
    <>
      <ShadowCard title={title}>
        <div id="address-form" className="form-max flex-20 column">
          <SectionSelector {...newProps} />
        </div>
      </ShadowCard>
    </>
  );
}

function SectionSelector(props) {
  const {
    data: { accountInformation, shippingInformation } = {},
    config,
    dictionary,
    isValid,
    defaultForm,
    setAddress,
  } = props;
  const form = useRecoilValue(formState);

  const {
    businessMarket: { iso2: accountCountry },
    countryInfo: { iso2: shippingCountry },
  } = form;

  const [setTopIsValid, setBottomIsValid] = useValidationConsolidator(
    ['top', 'bottom'],
    isValid,
  );

  const {
    businessMarket: { country: marketLabel } = {},
    countryInfo: { country: countryLabel } = {},
  } = form;

  const sections = [
    {
      defaultForm: Object.keys(defaultForm)
        .filter((key) => {
          return key.includes('ACCOUNT');
        })
        .reduce((acc, key) => ({ ...acc, [key]: defaultForm[key] }), {}),
      formType: 'account',
      isValid: setTopIsValid,
      country: accountCountry,
      label: marketLabel,
      sectionLabel: accountInformation,
      dictionary,
      config,
      form,
      setAddress,
    },
    {
      defaultForm: Object.keys(defaultForm)
        .filter((key) => {
          return key.includes('SHIPPING');
        })
        .reduce((acc, key) => ({ ...acc, [key]: defaultForm[key] }), {}),
      formType: 'shipping',
      isValid: setBottomIsValid,
      country: shippingCountry,
      label: countryLabel,
      sectionLabel: shippingInformation,
      dictionary,
      config,
      form,
      setAddress,
    },
  ];
  return sections.map((section, index) => {
    const { formType, country } = section;

    if (formType === 'shipping'){
      return <ShippingAddressSection {...section} key={index} />;
    }

    if (country === 'KR'){
      return <KRAddressSection {...section} key={index} />;
    } 

    return <ServiceObjectsAddressSection {...section} key={index} />;
  });
}

//the bottom section also known as the shipping section
function ShippingAddressSection(props) {
  const { isValid, dictionary, country } = props;
  const [set_toggle, set_shippingPhoneIsValid] = useValidationConsolidator(
    (country === 'KR' && envConfig.FEATURE_FLAG_PHONE_VALIDATION) ? ['toggle'] : ['toggle', 'ship_phone'],
    isValid,
  );

  // Check if phone field should be shown
  const showPhoneField = country === 'KR' && envConfig.FEATURE_FLAG_PHONE_VALIDATION;

  const newProps = {
    ...props,
    dictionary,
    isValid: set_toggle,
  };

  return (
    <>
      <AddressToggleController {...newProps} />
      {!showPhoneField && (
        <Input
          country={country}
          dictionary={dictionary}
          id="PHONE"
          isValid={set_shippingPhoneIsValid}
        />
      )}
    </>
  );
}

function AddressToggleController(props) {
  const {
    sectionLabel,
    dictionary,
    isValid,
    form,
    config: { forceSingleAddress = false } = {},
  } = props;

  const {
    businessMarket: { iso2: marketIso },
    countryInfo: { iso2: countryIso },
  } = form;

  const marketAndCountriesDifferent = marketIso !== countryIso;
  const [toggleIsChecked, set_toggleIsChecked] = useState(false);
  const [addressIsValid, set_addressIsValid] = useState(false);

  useEffect(() => {
    set_toggleIsChecked(!marketAndCountriesDifferent);
  }, []);

  useEffect(() => {
    isValid(toggleIsChecked || addressIsValid);
  }, [toggleIsChecked, addressIsValid]);

  const newProps = {
    ...props,
    isValid: set_addressIsValid,
    dictionary,
  };
  const label = findInDictionary(
    dictionary,
    'validation-accountAndShippingAddress-same',
  );
  if (forceSingleAddress) return null;
  return (
    <>
      {!toggleIsChecked && (
        <div style={{ margin: '20px auto', fontWeight: '400' }}>
          {sectionLabel}
        </div>
      )}
      {!marketAndCountriesDifferent && (
        <BlueSwitch
          label={label}
          defaultChecked={!marketAndCountriesDifferent}
          id="validation-accountAndShippingAddress-same"
          onChange={set_toggleIsChecked}
        />
      )}
      {!toggleIsChecked && countryIso !== 'KR' && <ServiceObjectsAddressSection {...newProps} />}
      {!toggleIsChecked && countryIso === 'KR' && <KRAddressSection {...newProps} />}
    </>
  );
}

