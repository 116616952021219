import { useEffect, useMemo, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Header from 'components/steps/Header';
import Footer from 'components/steps/Footer';
import ReviewOrder from 'components/steps/create-account/ReviewOrder';
import { NextContextProvider } from 'contexts/NextContext';
import useContentfulApi, { usePage } from 'hooks/useContentfulApi';
import useGetInputs from 'hooks/useGetInputs';
import Form from 'components/common/form/country/default';
import { getMarketConfigByCountry } from 'helpers/marketConfig';
import { post } from 'api/request';
import MainContent from 'components/common/layout/MainContent';
import Page from 'components/common/layout/Page';
import { Typography } from '@newulife/common';
import { useRecoilValue, useRecoilState, useRecoilValueLoadable } from 'recoil';
import { getRepName } from 'helpers/rep';
import { useProducts } from 'contexts/ProductContext';
import { useSubscriptions } from 'contexts/SubscriptionContext';
import CONTENTFUL from 'constants/contentful';
import { useEnvironment } from 'contexts/EnvironmentContext';
import { LoaderRing } from 'components/common/loaders/SpinnerLoader';
import { find, isEmpty } from 'lodash';
import { mergeProductsAndFetchedData } from 'helpers/shopifyHelpers';
import terms from 'api/terms';
import { getEntryByLocale } from 'api/contentful';


// import MarketsList from 'constants/markets';
import {
  formState,
  inputState,
  // marketsState,
  countriesState,
  kitsState as kitsRecoilState,
  repState,
} from 'state';
import formatImageSrc from 'helpers/formatImageSrc'

// const marketsThatAutoSelectCountry = new MarketsList()
//   .marketsThatAutoSelectCountry;

export default function CreateAccount() {
  const [form, setForm] = useRecoilState(formState);
  const [inputs, setInputs] = useRecoilState(inputState);
  const { state, contents: countries } = useRecoilValueLoadable(countriesState);
  const {
    countryInfo: { iso2: countryIso2 } = {},
    businessMarket: { iso2: marketIso2 } = {},
  } = useRecoilValue(formState);
  const isDevEnvironment = useEnvironment();
  const noSponsorRepDID = isDevEnvironment ? '1771248' : '1776366';
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const repDID = queryParams.get('repDID') === '1' ? noSponsorRepDID : queryParams.get('repDID') || noSponsorRepDID;
  const langKey = queryParams.get('langKey') || 'en-US';
  const countryKey = langKey?.split?.('-')?.[1];
  const productsParam = queryParams.get('product_ids');
  const isShopifyUser = !!productsParam;
  const returnURL = queryParams.get('rurl');
  const sponsor = useRecoilValueLoadable(repState(isShopifyUser ? repDID : null));

  useEffect(() => {
    if (
      isShopifyUser &&
      state === 'hasValue' && 
      sponsor.state === 'hasValue' && 
      (!countryIso2 ||
      (form.repDID !== sponsor.contents.RepDID && ( form.rep?.URL !== repDID )))
    ) {
      let newFormData = {};
      let data = countries.find(({ iso2 }) => countryKey === iso2);
      newFormData = { businessMarket: data, countryInfo: data };
      setForm({ 
        ...form, 
        ...newFormData, 

        rep: sponsor.contents,
        authRep: {},
        repDID: sponsor.contents.RepDID,
        repName: getRepName(sponsor.contents),
        locale: langKey,
        localeId: 1, // TODO
        moneyInfo: {
          countryName: 'USA',
          currencySymbol: '$',
          currencyTypeCode: 'USD',
        },
        isShopifyUser,
        referrer: returnURL,
      });
      setInputs({
        EMAIL: queryParams.get('email') || '',
        PHONE: queryParams.get('phone') || '',
        NAME_FIRST: queryParams.get('first_name') || '',
        NAME_LAST: queryParams.get('last_name') || '',
        SHIPPING_ADDRESS1: queryParams.get('address1') || '',
        ACCOUNT_ADDRESS1: queryParams.get('address1') || '',
        SHIPPING_ADDRESS2: queryParams.get('address2') || '',
        ACCOUNT_ADDRESS2: queryParams.get('address2') || '',
        SHIPPING_CITY: queryParams.get('city') || '',
        ACCOUNT_CITY: queryParams.get('city') || '',
        SHIPPING_STATE: queryParams.get('state') || '',
        ACCOUNT_STATE: queryParams.get('state') || '',
        SHIPPING_ZIP: queryParams.get('zip') || '',
        ACCOUNT_ZIP: queryParams.get('zip') || '',
      });
      // newFormData = marketsThatAutoSelectCountry.includes(data?.iso2)
      //   ? { businessMarket: data, countryInfo: data }
      //   : { businessMarket: data, countryInfo: {} };

      // clearCart();
      // resetAccount();
    }
  }, [state, sponsor.state, countryIso2, form.repDID, isShopifyUser, langKey, returnURL]);

  if (isShopifyUser ? !countryIso2 || sponsor.state !== 'hasValue' : false) {
    return <LoaderRing active />
  }

  return (
    <CreateAccountContents 
      isShopifyUser={isShopifyUser || form.isShopifyUser}
    />
  )
}

function CreateAccountContents({ isShopifyUser }) {
  const [productsLoading, setProductsLoading] = useState(false);
  const [form, setForm] = useRecoilState(formState);
  const loading = useRef(false);
  const { dispatch } = useProducts();
  const { dispatch: dispatchSub } = useSubscriptions();
  const { state: kitsState, contents: kitsContents } = useRecoilValueLoadable(kitsRecoilState);
  const data = usePage('create-account');
  const loaderData = useContentfulApi(CONTENTFUL.loader_screen);
  const inputs = useGetInputs();
  const {
    countryInfo: { iso2: countryIso2 } = {},
    businessMarket: { iso2: marketIso2 } = {},
  } = useRecoilValue(formState);
  const market = countryIso2 === 'CN' ? 'CN' : marketIso2;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productsParam = queryParams.get('product_ids');
  const allLoaded = !isEmpty(kitsState) && !isEmpty(kitsContents);
  const productsStr = productsParam?.split(',');
  const productsArr = productsStr?.map(productId => {
    const [id, quantity, autoship] = productId.split(':');
    return { id, quantity: parseInt(quantity), autoship: autoship === '1'};
  });
  const shopifyKitID = productsArr?.[productsArr?.length - 1]?.id;

  const config = useMemo(() => {
    return getMarketConfigByCountry(market);
  }, [market]);

  const getProductsByID = async () => {
    loading.current = true;
    setProductsLoading(true);

    post(`/api/enrollment/products`, {
      repDID: form.repDID,
      productIds: [...new Set(productsArr.map(o => o.id))],
      langKey: form.locale,
    })
      .then(data => {
        const {
          products,
          errors,
        } = data || {};
        const filteredProducts = products.filter(o => !find(errors, { ProductID: o.ProductID }));
        const starterKitProducts = kitsContents.find(o => o.ProductID === shopifyKitID);
        const { autoshipProducts, oneTimeProducts } = mergeProductsAndFetchedData(productsArr, filteredProducts);
        
        dispatchSub({
          type: 'replaceSubscriptions',
          subscriptions: autoshipProducts.map(o => ({
            ...o,
            SmallImageURL: formatImageSrc(o.SmallImageURL),
            LargeImageURL: formatImageSrc(o.LargeImageURL),
          })).filter(Boolean),
        });
        dispatch({
          type: 'replaceProductsAndKit',
          products: oneTimeProducts.map(o => ({
            ...o,
            SmallImageURL: formatImageSrc(o.SmallImageURL),
            LargeImageURL: formatImageSrc(o.LargeImageURL),
          })).filter(Boolean),
          starterKit: starterKitProducts ? {
            ...starterKitProducts,
            SmallImageURL: formatImageSrc(starterKitProducts.SmallImageURL),
            LargeImageURL: formatImageSrc(starterKitProducts.LargeImageURL),
            Quantity: 1,
            CurrencySymbol: '$',
            IsStarterKit: true,
          } : null,
        });
      })
      .catch(e => {
        console.log('error', e);
        setProductsLoading(false);
      })
      .finally(() => {
        setProductsLoading(false);
      });
  };

  useEffect(() => {
    if (allLoaded && isShopifyUser && !loading.current && !!productsStr) {
      getProductsByID();
    }
  }, [allLoaded, isShopifyUser, productsStr]);

  if (!data || !loaderData) return null;

  if (isShopifyUser ? productsLoading || !allLoaded : false) {
    return <LoaderRing active />
  }

  const {
    fields: {
      headerRef,
      banner1Ref: pageData,
      banner2Ref: modalData,
      pageTitle,
    } = {},
  } = data;

  return (
    <NextContextProvider>
      <Page className="create-account">
        <Header data={headerRef} />
        <MainContent>
          <section className="intro-text center">
            <Typography variant="header1">{pageTitle}</Typography>
          </section>
          <ReviewOrder data={{ pageData, modalData, isShopifyUser }} />
          <Form data={pageData} inputs={inputs} config={config} loaderData={loaderData} />
        </MainContent>
        <Footer data={headerRef} />
      </Page>
    </NextContextProvider>
  );
}
