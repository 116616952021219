import {
  Typography,
  Stack,
} from '@newulife/common'

export default function Header({ contentful }) {
  const [header, subTitle] = contentful;
  return (
    <Stack
      gap={2}
      textAlign="center"
      pb={4}
    >
      <Typography
        variant="header1"
      >
        {header}
      </Typography>
      <Typography
        variant="header2"
        data-testid="sub-title"
      >
        {subTitle}
      </Typography>
    </Stack>
  );
}
