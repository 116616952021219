import { createClient } from 'contentful';
import config from 'config';
import { handleError } from './request';

const client = createClient({
  space: config.REACT_APP_CONTENTFUL_SPACE,
  accessToken: config.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  environment: config.REACT_APP_CONTENTFUL_ENVIRONMENT,
});

export const getEntryByLocale = async (id, locale) =>
  await client.getEntry(id, { locale, include: 10 }).catch(handleError);

export const getContentType = (type, locale) =>
  client.getEntries({ content_type: type, locale }).catch(handleError);

export async function getEntryByContentTypeAndLocale(contentType, locale, market, type) {
    return client
      .getEntries({
        content_type: contentType,
        'fields.whitelistedMarkets[in]': market,
        'fields.type': type,
        limit: 1,
        locale,
      })
      .then(({ items }) => (items.length !== 0 ? items[0] : null));
  }

export async function getPageByMarket(page, locale, market) {
    return client
      .getEntries({
        content_type: 'homePageModel',
        'fields.whitelist[in]': market,
        'fields.pageID': page,
        limit: 1,
        locale,
        include: 10,
      })
      .then(({ items }) => (items.length !== 0 ? items[0] : null));
  }

const contentfulObj = {
  getContentType,
  getEntryByLocale,
  getEntryByContentTypeAndLocale,
  getPageByMarket,
  client,
};
export default contentfulObj;
