import user from '../api/user';
import { atom, selectorFamily, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom: persistGuid } = recoilPersist({ key: 'NUL_GUID' });
export const guidState = atom({
  key: 'guidState',
  default: '',
  effects: [persistGuid],
});

const { persistAtom: persistRepIdParam } = recoilPersist({ key: 'NUL_GUID' });
export const repIdParamState = atom({
  key: 'repIdParamState',
  default: '',
  effects: [persistRepIdParam],
});

const { persistAtom: persistIdParam } = recoilPersist({ key: 'NUL_GUID' });
export const idParamState = atom({
  key: 'idParamState',
  default: '',
  effects: [persistIdParam],
});

const { persistAtom: persistMarketParam } = recoilPersist({ key: 'NUL_MARKET' });
export const marketParamState = atom({
  key: 'marketParamState',
  default: '',
  effects: [persistMarketParam],
});

const { persistAtom: persistUplineDID } = recoilPersist({
  key: 'NUL_UPLINE_REP_DID',
});
export const uplineRepDIDState = atom({
  key: 'uplineRepDIDState',
  default: '',
  effects: [persistUplineDID],
});

const { persistAtom: persistRepPosition } = recoilPersist({
  key: 'NUL_REP_POSITION',
});
export const repPositionState = atom({
  key: 'repPositionState',
  default: '',
  effects: [persistRepPosition],
});

const { persistAtom: persistRepDID } = recoilPersist({ key: 'NUL_REPDID' });
export const repDIDState = atom({
  key: 'repDID',
  default: '',
  effects: [persistRepDID],
});

export const persistRepState = atom({
  key: 'PersistRep',
  default: false,
});

export const repState = selectorFamily({
  key: 'RepState',
  get:
    (repDID) =>
    ({ get }) => {
      let persistedRep = {};
      const persist = get(persistRepState);
      if (persist) persistedRep = JSON.parse(localStorage.getItem('NUL_REP'));
      return persistedRep && (!repDID || persistRepDID.repDID === repDID)
        ? persistedRep
        : get(repQuery(repDID || get(repDIDState)));
    },
});

const repQuery = selectorFamily({
  key: 'repQuery',
  get:
    (repDID) =>
    async ({ get }) => {
      let response = {};
      const guid = get(guidState);
      if (!repDID && guid) response = await user.getByGuid(get(guidState));
      if (response.error) throw response.error;
      if (response.RepDID || repDID) {
        const response2 = await user.get(response.RepDID || repDID);
        if (response2.error) throw response2.error;
        const response3 = await user.getLanguageById(
          response2.DefaultLocaleID || 1,
        );
        if (response3.error) throw response3.error;
        const rep = { ...response, ...response2, ...response3 };
        localStorage.setItem('NUL_REP', JSON.stringify(rep));
        return rep;
      } else {
        return {};
      }
    },
});

  // get: repDID => async ({ get }) => {
  //   let response = {}
  //   const guid = get(guidState)
  //   if (!repDID && guid) response = await user.getByGuid(get(guidState))
  //   if (response.error) throw response.error
  //   if (response === 'Invalid guid') {
  //     toast.error(response)
  //     throw new Error(response)
  //   }
  //   if (response.RepDID || repDID) {
  //     const response2 = await user.get(response.RepDID || repDID)
  //     if (typeof response2 === 'string' && response2.includes('Cancelled'))  {
  //       toast.error(response2)
  //       // Don't block, just notify with toast
  //       // throw response2
  //     }
  //     const response3 = await user.getLanguageById(response2.DefaultLocaleID || 1)
  //     if (response3.error) throw response3.error
  //     const rep = {...response, ...response2, ...response3};
  //     localStorage.setItem('NUL_REP', JSON.stringify(rep))
  //     return rep;
  //   } else {
  //     return {}
  //   }
  // },
// });


export const accountAddress = atom({
  key: 'accountAddress',
  default: {
    addressType: 'account',
    current: undefined,
    selected: 'current',
    serviceobjects: undefined,
  }
});

export const shippingAddress = atom({
  key: 'shippingAddress',
  default: {
    addressType: 'shipping',
    current: undefined,
    selected: 'current',
    serviceobjects: undefined,
  },
});

const { persistAtom: persistInputs } = recoilPersist({ key: 'NUL_INPUTS' });
export const inputState = atom({
  key: 'inputState',
  default: {},
  effects: [persistInputs],
});

export const addressState = selector({
  key: 'addressState',
  default: [],
  get: ({ get }) => {
    const address1 = get(accountAddress);
    const address2 = get(shippingAddress);
    return [address1, address2];
  },
  set: ({ set }, newValue) => {
    const [val1, val2] = newValue;
    set(accountAddress, val1);
    set(shippingAddress, val2);
  },
});
