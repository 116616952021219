import { atom, selector } from 'recoil';
import defaultLanguage from 'constants/defaultLanguage';
import { repPositionState, repState, uplineRepDIDState } from './userState';
import initialForm from 'constants/initialForm';
import { recoilPersist } from 'recoil-persist';
import { getRepName } from 'helpers/rep';

const { persistAtom: persistForm } = recoilPersist({key: 'NUL_FORM'})
export const formState = atom({
  key: 'formState',
  default: selector({
    key: 'formSelector',
    get: ({ get }) => {
      const form = initialForm()
      const rep = get(repState());

      return { 
        ...form, 
        rep,
        repDID: rep?.RepDID,
        uplineRepDID: get(uplineRepDIDState),
        repName: getRepName(rep),
        repPosition: get(repPositionState),
        locale: rep?.locale ? rep?.locale : defaultLanguage.locale,
        localeId: rep?.locale ? rep?.DefaultLocaleID : defaultLanguage.id,
        authRep: rep || {}, 
      }
    }
  }),
  effects: [persistForm]
})