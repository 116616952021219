/**
 * Function
 * Maintain lists of country functionality in one place
 *
 * Add a country to Service Objects
 * List of countries with dropdowns
 */

class MarketsList {
  constructor() {
    this.list = marketsList;
  }
  marketsThatAutoSelectCountry = [
    'TW', 
    'KR', 
    'JP', 
    'CN',
    // 'TH',
  ];
  socountriesNoValidation = ['US']; // US is the only country SO guarantees
  socountries = [
    // these countries need a secondary validation check
    ...this.socountriesNoValidation,
    'CA',
    'AU',
    'NZ',
    'DE',
    'BE',
    'NL',
    'GB',
  ];
  find(string) {
    // string can be iso2, market, or country
    const isoMatch = this.list.find(({ iso2 }) => string === iso2);
    if (isoMatch) return isoMatch;
    const marketMatch = this.list.find(({ market }) => string === market);
    if (marketMatch) return marketMatch;
    const countryMatch = this.list.find(({ country }) => string === country);
    if (countryMatch) return countryMatch;
    const nameMatch = this.list.find(({ name }) => string === name);
    if (nameMatch) return nameMatch;
  }
  getCountries() {
    return this.list.map(({ country }) => country);
  }
  getMarkets() {
    return this.list.map(({ name }) => name);
  }
  get_2() {
    return this.list.map(({ iso2 }) => iso2);
  }
  get_3() {
    return this.list.map(({ iso3 }) => iso3);
  }
  getDropdownCountries() {
    this.list = this.list.filter((market) => {
      const { iso2 } = market;
      return this.socountries.includes(iso2);
    });
    return this;
  }
  getDropdownCountriesNoValidation() {
    this.list = this.list.filter((market) => {
      const { iso2 } = market;
      return this.socountriesNoValidation.includes(iso2);
    });
    return this;
  }
}

const marketsList = [
  {
    name: 'USA',
    country: 'USA',
    iso2: 'US',
    iso3: 'USA',
    contentfulWhitelistKey: 'United States',
  },
  {
    name: 'Canada',
    country: 'Canada',
    iso2: 'CA',
    iso3: 'CAN',
    contentfulWhitelistKey: 'Canada NFR',
  },
  {
    name: 'Hong Kong Cross Market',
    country: 'China',
    iso2: 'CN',
    iso3: 'CHN',
    shipMarket: 'HK',
    contentfulWhitelistKey: 'China',
  },
  {
    name: 'Hong Kong',
    country: 'Hong Kong',
    iso2: 'HK',
    iso3: 'HKG',
    contentfulWhitelistKey: 'Hong Kong',
  },
  {
    name: 'Taiwan',
    country: 'Taiwan',
    iso2: 'TW',
    iso3: 'TWN',
    contentfulWhitelistKey: 'Taiwan',
  },
  {
    name: 'Australia NFR',
    country: 'Australia',
    iso2: 'AU',
    iso3: 'AUS',
    contentfulWhitelistKey: 'Australia NFR',
  },
  {
    name: 'New Zealand NFR',
    country: 'New Zealand',
    iso2: 'NZ',
    iso3: 'NZL',
    contentfulWhitelistKey: 'New Zealand NFR',
  },
  {
    name: 'Japan NFR',
    country: 'Japan',
    iso2: 'JP',
    iso3: 'JPN',
    contentfulWhitelistKey: 'Australia NFR',
  },
  {
    name: 'Germany NFR',
    country: 'Germany',
    iso2: 'DE',
    iso3: 'DEU',
    contentfulWhitelistKey: 'Germany',
  },
  {
    name: 'Malaysia',
    country: 'Malaysia',
    iso2: 'MY',
    iso3: 'MYS',
    contentfulWhitelistKey: 'Malaysia',
  },
  {
    name: 'Thailand',
    country: 'Thailand',
    iso2: 'TH',
    iso3: 'THA',
    contentfulWhitelistKey: 'Thailand',
  },
  {
    name: 'Singapore',
    country: 'Singapore',
    iso2: 'SG',
    iso3: 'SGP',
    contentfulWhitelistKey: 'Singapore',
  },
  {
    name: 'United Kingdom NFR',
    country: 'United Kingdom',
    iso2: 'GB',
    iso3: 'GBR',
    contentfulWhitelistKey: 'United Kingdom',
  },
  {
    name: 'Netherlands NFR',
    country: 'Netherlands',
    iso2: 'NL',
    iso3: 'NLD',
    contentfulWhitelistKey: 'Netherlands',
  },
  {
    name: 'Belgium NFR',
    country: 'Belgium',
    iso2: 'BE',
    iso3: 'BEL',
    contentfulWhitelistKey: 'Belgium',
  },
  {
    name: 'Korea (the Republic of)',
    country: 'Korea (the Republic of)',
    iso2: 'KR',
    iso3: 'KOR',
    contentfulWhitelistKey: 'Korea (the Republic of)',
  },
];

export default MarketsList;
