import { isEmpty } from 'lodash';
import { getRepName } from 'helpers/rep';
import config from 'config';
import {
  Box,
  Stack,
  Typography,
} from '@newulife/common'
import { useRecoilValue } from 'recoil';
import { formState } from 'state';

export default function LoggedInAvatar({ text, showRepDID }) {
  const { authRep } = useRecoilValue(formState);
  const host = config.REACT_APP_BYDESIGN_IMAGE_HOST_URL;

  return (
    <Stack
      direction="row"
      sx={{
        ml: 2,
        alignContent: 'center',
        flex: '0 0 auto',
        'img': {
          width: {
            xs: '40px',
            md: '45px',
          },
          height: 'auto',
          borderRadius: '50%',
        },
      }}
    >
      {authRep.PublicImageUrl && (
        <img
          src={`${host}/${authRep.PublicImageUrl}`}
          alt="Logged In Avatar"
          width="50px"
          height="50px"
        />
      )}
      {!isEmpty(authRep) && (
        <Box
          sx={{
            pl: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            flex: '0 0 auto',
            display: {
              xs: 'none',
              md: 'flex',
            },
          }}
        >
          <Typography
            variant="subbody2"
          >
            {text}
          </Typography>
          <Typography
            variant="subbody1"
          >
            {showRepDID ? authRep.RepDID : getRepName(authRep)}
          </Typography>
        </Box>
      )}
    </Stack>
  );
}
